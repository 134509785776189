import {lazy} from 'react';
import {loadComponent} from 'app/selectors/Helpers';

const Commercial = loadComponent(lazy(() => import('../components/CommercialComponent')));
const Rent = loadComponent(lazy(() => import('../components/RentComponent')));
const Gallery = loadComponent(lazy(() => import('../components/GalleryComponent')));

export const routes: typeof window.kinosite.routes = [
	{
		path: "/partners/commercial",
		component: Commercial,
		exact: true,
		key: 2
	},
	{
		path: "/partners/rent",
		component: Rent,
		exact: true,
		key: 3
	},
	{
		path: "/gallery",
		component: Gallery,
		key: 4
	},
];
